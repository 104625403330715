define('ember-auto-focus/components/auto-focus', ['exports', 'ember-component', 'ember-runloop'], function (exports, _emberComponent, _emberRunloop) {
  'use strict';

  var AutoFocusComponent = _emberComponent['default'].extend({
    tagName: 'span',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _emberRunloop.scheduleOnce)('afterRender', this, '_autofocus');
    },

    _autofocus: function _autofocus() {
      if (this.getAttr('disabled')) {
        return false;
      }
      var selector = this.getAttr('selector') || '>:first';
      this.$(selector).focus();
    }
  });

  AutoFocusComponent.reopenClass({
    positionalParams: ['selector']
  });

  exports['default'] = AutoFocusComponent;
});