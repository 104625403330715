define('ember-scroll-to-top/components/scroll-top', ['exports', 'ember-component', 'jquery', 'ember-evented/on'], function (exports, _emberComponent, _jquery, _emberEventedOn) {
  'use strict';

  exports['default'] = _emberComponent['default'].extend({
    classNames: 'ember-scroll-to-top',
    animate: false,
    toggleWhenScroll: null,
    toggleStartOffset: 100,
    effectToToggle: 'default',
    shouldShowElement: true,

    _effectToToggleFuncs: {
      'default': {
        show: 'show',
        hide: 'hide'
      },
      'fade': {
        show: 'fadeIn',
        hide: 'fadeOut'
      }
    },

    setup: (0, _emberEventedOn['default'])('didInsertElement', function () {
      var _this = this;

      (0, _jquery['default'])(document).on('scroll', function (e) {
        if (_this.get('onScroll')) {
          _this.get('onScroll')(e);
        }
        _this._onWindowScroll.call(_this);
      });

      this._onWindowScroll({ initial: true });
    }),

    _onWindowScroll: function _onWindowScroll() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      if (this.get('toggleWhenScroll')) {
        var _getProperties = this.getProperties('element', '_effectToToggleFuncs', 'effectToToggle');

        var element = _getProperties.element;
        var _effectToToggleFuncs = _getProperties._effectToToggleFuncs;
        var effectToToggle = _getProperties.effectToToggle;
        var initial = options.initial;

        var effectFuncs = initial ? _effectToToggleFuncs['default'] : _effectToToggleFuncs[effectToToggle] || _effectToToggleFuncs['default'];

        if ((0, _jquery['default'])(window).scrollTop() > this.get('toggleStartOffset')) {
          (0, _jquery['default'])(element)[effectFuncs['show']]();
        } else {
          (0, _jquery['default'])(element)[effectFuncs['hide']]();
        }
      }
    },

    actions: {
      scrollToTop: function scrollToTop() {
        var _this2 = this;

        var target = (0, _jquery['default'])('html, body');
        var animate = this.get('animate');
        if (animate) {
          target.animate({ scrollTop: 0 }, parseFloat(animate), function () {
            if (_this2.get('onScrollToTop')) {
              _this2.get('onScrollToTop')();
            }
          });
        } else {
          target.scrollTop(0);
          if (this.get('onScrollToTop')) {
            this.get('onScrollToTop')();
          }
        }
      }
    }
  });
});