define('ember-cli-icheck/components/i-check', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	exports['default'] = _ember['default'].Checkbox.extend({

		_icheck: null, // iCheck instance

		setup: (function () {
			var that = this;

			_ember['default'].assert("iCheck has to exist on Ember.$.fn.iCheck", _ember['default'].$.fn.iCheck);

			var icheck = this.$().iCheck({
				checkboxClass: 'icheckbox_square-blue',
				radioClass: 'iradio_square-blue',
				increaseArea: '20%'
			});

			icheck.on('ifToggled', function (ev) {
				that.set('checked', ev.target.checked);
			});

			this.set('_icheck', icheck);
		}).on('didInsertElement'),

		_checkedChanged: (function () {
			var checked = this.get('checked') ? 'check' : 'uncheck';
			this.get('_icheck').iCheck(checked);
		}).observes('checked'),

		teardown: (function () {

			if (this._icheck) {
				this.$().iCheck('destroy');
			}

			this.removeObserver('checked', this._checkedChanged);
		}).on('willDestroyElement')
	});
});