define('ember-validators/utils/unwrap-proxy', ['exports'], function (exports) {
  'use strict';

  exports['default'] = unwrapProxy;
  exports.isProxy = isProxy;
  var get = Ember.get;

  function unwrapProxy(_x) {
    var _again = true;

    _function: while (_again) {
      var o = _x;
      _again = false;
      if (isProxy(o)) {
        _x = get(o, 'content');
        _again = true;
        continue _function;
      } else {
        return o;
      }
    }
  }

  function isProxy(o) {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }
});